/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  // {
    
  //   description:
  //     'Humanities Association 2023 Class 6 - 7',
  //   href : '/humanity-club-2023-cls6-7'
 
  // },
  // {
    
  //   description:
  //     'Humanities Association 2023 Class 8 - 12',
  //   href : '/humanity-club-2023'
 
  // },
  {
    
    description:
      'Crisis Management',
    href : '/crisis-management'
 
  },
  {
    
    description:
      'Humanities Association',
      href : '/humanity-club'
 
  },
  {
    
    description:
      'Prabhat Pheri',
      href : '/prabhat-pheri'
 
  },
  {
    
    description:
      'Rashtriya Ekta Divas',
    href : '/rashtriya-ekta-divas'
 
  },
];

const HumanityClub = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Humanity Club 2022-23
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href={item.href}>
                <Typography variant='h6'>
                  {item.description}
                  
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HumanityClub;
